import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <h1>Welcom to Vidai services</h1>
    </div>
  );
}

export default App;
